<template>
  <el-card>
    <el-row>
      <el-button @click="dialogFormVisible = true" style="float: right"
        >新增更新信息</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table :data="tableData" border stripe row-key="id">
        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column prop="softVersionTypeDisplayName" label="软件类型">
        </el-table-column>
        <el-table-column prop="versionNewCode" label="新版本号">
        </el-table-column>
        <el-table-column label="旧版本号" prop="versionOldCode">
        </el-table-column>
        <el-table-column prop="createTime" label="更新时间"> </el-table-column>
        <el-table-column prop="versionInfo" label="更新内容"> </el-table-column>
      </el-table>
    </el-row>

    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <!-- 新增版本信息 -->
    <el-dialog
      title="收货地址"
      :visible.sync="dialogFormVisible"
      @close="dialogCloseHandler"
    >
      <el-form ref="addNewFormRef" :rules="formRules" :model="form">
        <el-form-item prop="newVersion" label="新版本号">
          <el-input v-model="form.newVersion" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="oldVersion" label="旧版本号">
          <el-input v-model="form.oldVersion" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="message" label="更新内容">
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.message"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { getAll, AddNew, GetInfo } from '@/api/softVersion.js'
export default {
  data() {
    return {
      tableData: [],
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        productBrandId: null,
        productTypeId: null,
      },
      //
      dialogFormVisible: false,
      form: {
        newVersion: '',
        oldVersion: '',
        message: '',
      },
      formRules: {
        newVersion: [
          { required: true, message: '请输入新版本号', trigger: 'blur' },
        ],
        oldVersion: [
          { required: true, message: '请输入旧版本号', trigger: 'blur' },
        ],
        message: [
          { required: true, message: '请输入更新内容', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.flashTable()
  },
  methods: {
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    handleSizeChange(val) {
      this.queryFilter.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    async getTableData() {
      let { data } = await getAll(this.queryFilter)
      this.tableData = data.data
      this.queryFilter.total = data.total
    },
    dialogCloseHandler() {
      this.$refs.addNewFormRef.resetFields()
    },
    dialogFormSubmit() {
      this.$refs.addNewFormRef.validate(async (valid) => {
        if (!valid) {
          return this.$message.error('当前信息填写的不完整')
        }

        await AddNew(this.form)

        this.flashTable()

        this.dialogFormVisible = false
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
